import ReportArea from './ReportArea.jsx'
import ChatInterface from './ChatInterface.jsx'

const AiArenaLayout = () => {
    return (
        <div className="flex flex-col bg-gray-100">
          <ReportArea />
          <ChatInterface />
        </div>
      )
  };
  export default AiArenaLayout;
  