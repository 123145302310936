import { BarChart, PieChart } from 'lucide-react'

export default function ReportArea() {
  return (
    <div className="flex-1 overflow-auto p-6 bg-white shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center">AI Arena: Your Report</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ReportSection
          title="Valuation Metrics"
          description="Key indicators of the company's market value"
          metrics={[
            { name: 'P/E Ratio', value: '22.5' },
            { name: 'Price to Book', value: '3.2' },
            { name: 'EV/EBITDA', value: '12.8' },
          ]}
          Chart={BarChart}
        />
        <ReportSection
          title="Profitability Metrics"
          description="Measures of the company's ability to generate earnings"
          metrics={[
            { name: 'Gross Margin', value: '35%' },
            { name: 'Operating Margin', value: '18%' },
            { name: 'Net Profit Margin', value: '12%' },
          ]}
          Chart={PieChart}
        />
      </div>
    </div>
  )
}

function ReportSection({ title, description, metrics, Chart }) {
  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 mb-4">{description}</p>
      <div className="grid grid-cols-2 gap-4">
        <div>
          {metrics.map((metric, index) => (
            <div key={index} className="mb-2">
              <span className="font-medium">{metric.name}:</span> {metric.value}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center">
          <Chart className="w-24 h-24 text-blue-500" />
        </div>
      </div>
    </div>
  )
}