/**
 * Calculate daily change (close - open).
 * @param {number} open - Opening price.
 * @param {number} close - Closing price.
 * @returns {number} Daily price change.
 */
export const calculateDailyChange = (open, close) => {
	return parseFloat(close) - parseFloat(open);
};

/**
 * Calculate daily volatility percentage.
 * This function leverages calculateDailyChange to use its results internally.
 * @param {number} high - Highest price of the day.
 * @param {number} low - Lowest price of the day.
 * @param {number} open - Opening price.
 * @param {number} close - Closing price.
 * @returns {object} An object with daily volatility and daily change.
 */
export const calculateDailyVolatility = (high, low, open, close) => {
	const dailyChange = calculateDailyChange(open, close);
	const dailyVolatility =
		((parseFloat(high) - parseFloat(low)) / parseFloat(open)) * 100;
	return { dailyChange, dailyVolatility };
};

/**
 * Calculate Simple Moving Average (SMA).
 * @param {Array} data - Array of prices.
 * @param {number} period - Number of days over which to calculate the average.
 * @returns {number} SMA for the given period.
 */
export const calculateSMA = (data, period) => {
	const sum = data
		.slice(0, period)
		.reduce((acc, cur) => acc + parseFloat(cur), 0);
	return sum / period;
};

/**
 * Calculate Exponential Moving Average (EMA).
 * This function internally calls calculateSMA for initializing the EMA.
 * @param {Array} data - Array of prices.
 * @param {number} period - Number of days for the EMA.
 * @returns {number} EMA for the given period.
 */
export const calculateEMA = (data, period) => {
	const sma = calculateSMA(data, period);
	const k = 2 / (period + 1);
	return data
		.slice(period)
		.reduce((ema, price) => ema * (1 - k) + price * k, sma);
};

/**
 * Calculate Relative Strength Index (RSI).
 * This function does not directly depend on other calculations but is self-contained.
 * @param {Array} data - Array of closing prices.
 * @param {number} period - Number of days for the RSI calculation.
 * @returns {number} RSI value.
 */
export const calculateRSI = (data, period) => {
	let gains = 0,
		losses = 0;
	for (let i = 1; i <= period; i++) {
		const change = parseFloat(data[i]) - parseFloat(data[i - 1]);
		if (change > 0) gains += change;
		else losses -= change;
	}
	const rs = gains / losses || 0;
	return 100 - 100 / (1 + rs);
};

/**
 * Calculate Moving Average Convergence Divergence (MACD).
 * It uses calculateEMA to get EMA values.
 * @param {Array} data - Array of closing prices.
 * @param {number} shortPeriod - Short period for EMA (usually 12).
 * @param {number} longPeriod - Long period for EMA (usually 26).
 * @returns {number} MACD value.
 */
export const calculateMACD = (data, shortPeriod = 12, longPeriod = 26) => {
	const emaShort = calculateEMA(data, shortPeriod);
	const emaLong = calculateEMA(data, longPeriod);
	return emaShort - emaLong;
};

/**
 * Calculate Bollinger Bands.
 * This internally uses calculateSMA for the middle band calculation.
 * @param {Array} data - Array of closing prices.
 * @param {number} period - Number of periods for the SMA.
 * @param {number} multiplier - Multiplier for the standard deviation.
 * @returns {Object} Object containing Bollinger Bands
 */
export const calculateBollingerBands = (data, period, multiplier) => {
	const middleBand = calculateSMA(data, period);
	const squaredDiffs = data
		.slice(0, period)
		.map((price) => Math.pow(price - middleBand, 2));
	const variance = squaredDiffs.reduce((acc, cur) => acc + cur, 0) / period;
	const stdDev = Math.sqrt(variance);
	return {
		upperBand: middleBand + multiplier * stdDev,
		middleBand: middleBand,
		lowerBand: middleBand - multiplier * stdDev,
	};
};

/**
 * Perform volume analysis to find trends.
 * This function is independent and does not require input from other functions.
 * @param {Array} volumes - Array of daily volumes.
 * @returns {string} Volume trend indication.
 */
export const analyzeVolumeTrend = (volumes) => {
	const latestChange = volumes.slice(-1)[0] - volumes.slice(-2)[0];
	if (latestChange > 0) return "Increasing volume, bullish signal";
	if (latestChange < 0) return "Decreasing volume, bearish signal";
	return "Stable volume, no clear trend";
};

/**
 * Calculate Sharpe Ratio.
 * @param {number} portfolioReturn - Portfolio return (in percentage).
 * @param {number} riskFreeRate - Risk-free rate (in percentage).
 * @param {number} portfolioVolatility - Portfolio standard deviation (in percentage).
 * @returns {number} Sharpe Ratio.
 */
export const calculateSharpeRatio = (portfolioReturn, riskFreeRate, portfolioVolatility) => {
    return (portfolioReturn - riskFreeRate) / portfolioVolatility;
};

/**
 * Calculate Beta.
 * @param {Array} stockReturns - Array of stock returns.
 * @param {Array} marketReturns - Array of market returns.
 * @returns {number} Beta value.
 */
export const calculateBeta = (stockReturns, marketReturns) => {
    const avgStockReturn = stockReturns.reduce((acc, val) => acc + val, 0) / stockReturns.length;
    const avgMarketReturn = marketReturns.reduce((acc, val) => acc + val, 0) / marketReturns.length;

    const covariance = stockReturns.reduce(
        (acc, val, idx) => acc + (val - avgStockReturn) * (marketReturns[idx] - avgMarketReturn),
        0
    ) / stockReturns.length;

    const variance = marketReturns.reduce(
        (acc, val) => acc + Math.pow(val - avgMarketReturn, 2),
        0
    ) / marketReturns.length;

    return covariance / variance;
};

/**
 * Calculate Portfolio Volatility.
 * @param {Array} portfolioReturns - Array of portfolio returns.
 * @returns {number} Portfolio standard deviation (volatility).
 */
export const calculatePortfolioVolatility = (portfolioReturns) => {
    const meanReturn = portfolioReturns.reduce((acc, val) => acc + val, 0) / portfolioReturns.length;
    const variance = portfolioReturns.reduce((acc, val) => acc + Math.pow(val - meanReturn, 2), 0) / portfolioReturns.length;
    return Math.sqrt(variance);
};

/**
 * Calculate Maximum Drawdown.
 * @param {Array} portfolioValues - Array of portfolio values over time.
 * @returns {number} Maximum drawdown percentage.
 */
export const calculateMaxDrawdown = (portfolioValues) => {
    let maxPeak = -Infinity;
    let maxDrawdown = 0;

    for (const value of portfolioValues) {
        if (value > maxPeak) maxPeak = value;
        const drawdown = ((maxPeak - value) / maxPeak) * 100;
        if (drawdown > maxDrawdown) maxDrawdown = drawdown;
    }

    return maxDrawdown;
};
