import { useState, useRef, useEffect } from 'react'
import { Send, Paperclip } from 'lucide-react'

export default function ChatInterface() {
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const chatContainerRef = useRef(null)

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [messages])

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { type: 'user', content: input }])
      setInput('')
      setIsProcessing(true)
      // Simulate AI response
      setTimeout(() => {
        setMessages(prev => [...prev, { type: 'ai', content: 'This is a simulated AI response.' }])
        setIsProcessing(false)
      }, 2000)
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setIsUploading(true)
      // Simulate file upload
      let progress = 0
      const interval = setInterval(() => {
        progress += 10
        setUploadProgress(progress)
        if (progress >= 100) {
          clearInterval(interval)
          setIsUploading(false)
          setUploadProgress(0)
          setMessages(prev => [...prev, { type: 'user', content: `File uploaded: ${file.name}` }])
        }
      }, 500)
    }
  }

  return (
    <div className="h-[58vh] flex flex-col bg-gray-800 text-white">
      <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[70%] rounded-lg p-3 ${
                message.type === 'user' ? 'bg-blue-600' : 'bg-gray-700'
              }`}
            >
              {message.content}
            </div>
          </div>
        ))}
        {isProcessing && (
          <div className="flex justify-start">
            <div className="bg-gray-700 rounded-lg p-3">AI is processing your request...</div>
          </div>
        )}
      </div>
      <div className="p-4 bg-gray-900">
        {isUploading && (
          <div className="mb-2">
            <div className="h-2 bg-gray-700 rounded-full">
              <div
                className="h-2 bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </div>
        )}
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 bg-gray-700 text-white rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label className="cursor-pointer">
            <Paperclip className="text-gray-400 hover:text-white transition-colors" />
            <input type="file" className="hidden" onChange={handleFileUpload} />
          </label>
          <button
            onClick={handleSend}
            className="bg-blue-500 hover:bg-blue-600 rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  )
}