import React, { useState } from 'react';
import { Settings, X } from 'lucide-react';

const metricCategories = [
  {
    name: 'Valuation Metrics',
    definition: 'Valuation metrics help determine whether an investment is overvalued, undervalued, or fairly priced.',
    metrics: [
      { name: 'Price-to-Earnings (P/E) Ratio', definition: 'Compares a company\'s stock price to its earnings per share.' },
      { name: 'Price-to-Book (P/B) Ratio', definition: 'Compares a company\'s market value to its book value.' },
      { name: 'Price-to-Sales (P/S) Ratio', definition: 'Compares a company\'s stock price to its revenues.' },
      { name: 'Enterprise Value-to-EBITDA (EV/EBITDA)', definition: 'Compares a company\'s total value to its earnings before interest, taxes, depreciation, and amortization.' },
    ],
  },
  {
    name: 'Profitability Metrics',
    definition: 'Profitability metrics assess how effectively a company generates income.',
    metrics: [
      { name: 'Return on Equity (ROE)', definition: 'Measures a company\'s profitability in relation to shareholders\' equity.' },
      { name: 'Return on Assets (ROA)', definition: 'Indicates how efficiently a company is using its assets to generate profit.' },
      { name: 'Net Profit Margin', definition: 'Shows the percentage of revenue that translates into profit.' },
    ],
  },
  {
    name: 'Growth Metrics',
    definition: 'Growth metrics indicate a company\'s ability to expand.',
    metrics: [
      { name: 'Earnings Per Share (EPS) Growth (3Y, 5Y)', definition: 'Measures the growth in a company\'s earnings per share over 3 and 5 year periods.' },
      { name: 'Revenue Growth (YoY)', definition: 'Shows the year-over-year increase in a company\'s revenue.' },
      { name: 'Dividend Growth Rate', definition: 'Indicates how fast a company\'s dividend payments are increasing.' },
    ],
  },
  {
    name: 'Risk and Volatility Metrics',
    definition: 'Risk and volatility metrics help assess portfolio risk.',
    metrics: [
      { name: 'Beta', definition: 'Measures a stock\'s volatility in relation to the overall market.' },
      { name: 'Sharpe Ratio', definition: 'Calculates risk-adjusted return, showing return per unit of risk.' },
      { name: 'Standard Deviation of Returns', definition: 'Measures the dispersion of a stock\'s returns from its mean.' },
    ],
  },
  {
    name: 'Liquidity and Leverage Metrics',
    definition: 'These metrics measure a company\'s financial health and liquidity.',
    metrics: [
      { name: 'Debt-to-Equity (D/E) Ratio', definition: 'Compares a company\'s total liabilities to its shareholder equity.' },
      { name: 'Current Ratio', definition: 'Measures a company\'s ability to pay short-term obligations.' },
      { name: 'Interest Coverage Ratio', definition: 'Shows how easily a company can pay interest on its outstanding debt.' },
    ],
  },
];

function MetricsCustomizer({sendToParent}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState(
    metricCategories.reduce((acc, category) => {
      acc[category.name] = category.metrics.map(metric => metric.name);
      return acc;
    }, {})
  );
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [additionalContext, setAdditionalContext] = useState('');

  const handleMetricToggle = (category, metric) => {
    setSelectedMetrics(prev => {
      const updatedMetrics = { ...prev };
      if (updatedMetrics[category].includes(metric)) {
        updatedMetrics[category] = updatedMetrics[category].filter(m => m !== metric);
      } else {
        updatedMetrics[category] = [...updatedMetrics[category], metric];
      }
      return updatedMetrics;
    });
  };

  const handleCategoryToggle = (category) => {
    setExpandedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSave = () => {
    sendToParent(selectedMetrics, additionalContext);
    setIsOpen(false);
  };

  return (
    <div className="p-4 flex justify-center">
      <button
        onClick={() => setIsOpen(true)}
        className="bg-gradient-to-r from-black to-blue-800 text-white py-2 px-4 rounded flex items-center space-x-2"
      >
        <Settings className="w-5 h-5" />
        <span>Customize Metrics & See The Magic!</span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-[800px] max-h-[97vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Select Metrics</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <p className="text-gray-600 mb-4">
              Choose the metrics you want to include in your analysis.
            </p>

            <div className="space-y-4">
              {metricCategories.map((category) => (
                <div key={category.name} className="border rounded-lg p-4">
                  <button
                    onClick={() => handleCategoryToggle(category.name)}
                    className="flex justify-between items-center w-full text-left font-semibold mb-2"
                  >
                    <span>{category.name}</span>
                    <span>{expandedCategories.includes(category.name) ? '−' : '+'}</span>
                  </button>
                  <p className="text-sm text-gray-600 mb-2">{category.definition}</p>
                  {expandedCategories.includes(category.name) && (
                    <div className="space-y-2">
                      {category.metrics.map((metric) => (
                        <div key={metric.name} className="flex items-start space-x-2">
                          <input
                            type="checkbox"
                            id={metric.name}
                            checked={selectedMetrics[category.name].includes(metric.name)}
                            onChange={() => handleMetricToggle(category.name, metric.name)}
                            className="mt-1"
                          />
                          <div>
                            <label
                              htmlFor={metric.name}
                              className="text-sm font-medium"
                            >
                              {metric.name}
                            </label>
                            <p className="text-sm text-gray-600">
                              {metric.definition}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <textarea
              placeholder="Enter any additional notes or context here"
              value={additionalContext}
              onChange={(e) => setAdditionalContext(e.target.value)}
              className="mt-4 p-2 border rounded w-full resize-y max-h-40"
            />

            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={() => setIsOpen(false)}
                className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MetricsCustomizer;

