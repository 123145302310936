import React from 'react';
import { FileIcon, ImageIcon } from 'lucide-react';

export function ChatMessage({ message }) {
  return (
    <div className="bg-gray-100 p-3 rounded-lg">
      <p>{message.text}</p>
      {message.files && message.files.length > 0 && (
        <div className="mt-2 space-y-1">
          {message.files.map((file) => (
            <div key={file.name} className="flex items-center space-x-2 text-sm text-gray-500">
              {file.type.startsWith('image/') ? (
                <ImageIcon className="h-4 w-4" />
              ) : (
                <FileIcon className="h-4 w-4" />
              )}
              <span>{file.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}