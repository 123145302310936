import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, X, Send, Paperclip, Loader } from 'lucide-react';
import { ChatMessage } from './ChatMessage';

export function FloatingChat() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [files, setFiles] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = () => {
    if (inputValue.trim() || files.length > 0) {
      const newMessage = {
        id: Date.now().toString(),
        text: inputValue,
        files: files,
      };
      setMessages([...messages, newMessage]);
      setInputValue('');
      setFiles([]);
      setIsTyping(false);
    }
  };

  const handleFileUpload = (event) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const handleTyping = (event) => {
    setInputValue(event.target.value);
    setIsTyping(event.target.value.length > 0);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="rounded-full w-12 h-12 bg-blue-500 text-white hover:bg-blue-600 flex items-center justify-center"
        >
          <MessageCircle className="h-6 w-6" />
        </button>
      )}
      {isOpen && (
        <div className="bg-white border rounded-lg shadow-lg w-80 sm:w-96 flex flex-col">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="font-semibold">Feedback & Chat</h2>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
          <div
            ref={chatRef}
            className="flex-1 overflow-y-auto p-4 space-y-4 max-h-96"
          >
            {messages.map((message) => (
              <ChatMessage key={message.id} message={message} />
            ))}
          </div>
          <div className="p-4 border-t">
            <div className="flex items-center space-x-2 mb-2">
              <button
                onClick={() => fileInputRef.current?.click()}
                className="p-2 rounded-full hover:bg-gray-100"
              >
                <Paperclip className="h-4 w-4" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                className="hidden"
                multiple
              />
              {files.map((file) => (
                <div key={file.name} className="text-sm text-gray-500">
                  {file.name}
                </div>
              ))}
            </div>
            <div className="flex items-center space-x-2">
              <textarea
                value={inputValue}
                onChange={handleTyping}
                placeholder="Type your message..."
                className="flex-1 p-2 border rounded-md resize-none"
                rows={1}
              />
              <button
                onClick={handleSend}
                disabled={!inputValue && files.length === 0}
                className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
              >
                <Send className="h-4 w-4" />
              </button>
            </div>
            {isTyping && (
              <div className="text-sm text-gray-500 mt-2">
                <Loader className="h-4 w-4 animate-spin inline-block mr-2" />
                Typing...
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

