import React, { useState } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { X, ArrowRight, DollarSign, TrendingUp, Activity, Droplet } from 'lucide-react';
import { IoInformationCircleSharp } from "react-icons/io5";

const COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6'];

export default function FinanceMetrics({ company, onClose }) {
  console.log("🚀 ~ FinanceMetrics ~ company:", company);
  const [activeTab, setActiveTab] = useState('valuation');

  const tabs = [
    { id: 'valuation', label: 'Valuation', icon: DollarSign },
    { id: 'profitability', label: 'Profitability', icon: TrendingUp },
    { id: 'growth', label: 'Growth', icon: ArrowRight },
    { id: 'risk', label: 'Risk & Volatility', icon: Activity },
    { id: 'liquidity', label: 'Liquidity & Leverage', icon: Droplet },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full flex items-center justify-center p-4" onClick={onClose}>
      <div className="relative bg-white rounded-xl shadow-2xl w-full max-w-4xl" onClick={e => e.stopPropagation()}>
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold text-gray-900">{company.symbol}</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500 transition-colors">
              <X size={24} />
            </button>
          </div>
          <div className="flex space-x-2 mb-6 overflow-x-auto pb-2">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-full transition-colors ${
                  activeTab === tab.id
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-500 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab(tab.id)}
              >
                <tab.icon size={16} className="mr-2" />
                {tab.label}
              </button>
            ))}
          </div>
          <div className="bg-gray-50 rounded-xl p-6">
            {activeTab === 'valuation' && (
              <ValuationMetrics company={company} />
            )}
            {activeTab === 'profitability' && (
              <ProfitabilityMetrics company={company} />
            )}
            {activeTab === 'growth' && (
              <GrowthMetrics company={company} />
            )}
            {activeTab === 'risk' && (
              <RiskMetrics company={company} />
            )}
            {activeTab === 'liquidity' && (
              <LiquidityMetrics company={company} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function MetricCard({ title, value, change, info }) {
  const isPositive = parseFloat(change) > 0;
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-sm font-medium text-gray-500 mb-1 flex items-center">{title} {info && (
        <div className="relative group ml-2">
        <IoInformationCircleSharp
          className="text-gray-400 hover:text-blue-500 cursor-pointer"
          size={16}
        />
        <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 bg-black text-white text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none" style={{minWidth: "15vw", maxWidth: "20vw"}}>
          {info}
        </div>
      </div>
      )}</h3>
      <p className="text-2xl font-bold text-gray-900">{value}</p>
      {change && (
        <p className={`text-sm font-medium ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
          {isPositive ? '↑' : '↓'} {change}
        </p>
      )}
    </div>
  );
}
function ValuationMetrics({ company }) {
  const metrics = company?.metrics || {};

  // Extract relevant metrics
  const pe = metrics.peBasicExclExtraTTM || 0;
  const pb = metrics.pbQuarterly || 0;
  const ps = metrics.psTTM || 0;
  const evEbitda = metrics['currentEv/freeCashFlowAnnual'] || 0;

  // Data for BarChart
  const data = [
    { name: 'P/E', value: pe },
    { name: 'P/B', value: pb },
    { name: 'P/S', value: ps },
    { name: 'EV/EBITDA', value: evEbitda },
  ];

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 flex items-center">Valuation Metrics
        <div className="relative group ml-2">
          <IoInformationCircleSharp
            className="hover:text-blue-500 cursor-pointer"
            size={16}
          />
          <div className="absolute left-1/2 -translate-x-1/2 font-normal bottom-full mb-2 bg-black text-white text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none w-[20vw]">
            Valuation metrics help determine whether an investment is overvalued, undervalued, or fairly priced.
          </div>
        </div>
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <MetricCard
          title="P/E Ratio"
          info="Measures a company's current share price relative to its earnings per share."
          value={`${pe.toFixed(2)}`}
        />
        <MetricCard
          title="P/B Ratio"
          info="Compares a company's market value to its book value, indicating if it's over- or undervalued."
          value={`${pb.toFixed(2)}`}
        />
        <MetricCard
          title="P/S Ratio"
          info="Shows how much investors are paying for each dollar of a company's revenue."
          value={`${ps.toFixed(2)}`}
        />
        <MetricCard
          title="EV/EBITDA"
          info="Assesses a company's overall value relative to its earnings before interest, taxes, depreciation, and amortization."
          value={`${evEbitda.toFixed(2)}`}
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#3B82F6" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}


function ProfitabilityMetrics({ company }) {
  const metrics = company?.metrics || {};

  // Extract relevant metrics
  const roe = metrics.roeTTM || 0;
  const roa = metrics.roaTTM || 0;
  const netProfitMargin = metrics.netProfitMarginTTM || 0;

  // Data for PieChart
  const data = [
    { name: 'ROE', value: roe },
    { name: 'ROA', value: roa },
    { name: 'Net Profit Margin', value: netProfitMargin },
  ];

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 flex items-center">Profitability Metrics
      <div className="relative group ml-2">
        <IoInformationCircleSharp
          className=" hover:text-blue-500 cursor-pointer"
          size={16}
        />
        <div className="absolute left-1/2 -translate-x-1/2 font-normal bottom-full mb-2 bg-black text-white text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none w-[20vw]">
        Profitability metrics assess the efficiency of companies in generating income.
        </div>
      </div>
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
        <MetricCard 
          title="ROE" 
          info="Measures how effectively shareholder capital is utilized." 
          value={`${roe.toFixed(2)}%`} 
        />
        <MetricCard 
          title="ROA" 
          info="Highlights a company's ability to use its assets to generate profits." 
          value={`${roa.toFixed(2)}%`} 
        />
        <MetricCard 
          title="Net Profit Margin" 
          info="Tracks profitability as a percentage of total revenue." 
          value={`${netProfitMargin.toFixed(2)}%`} 
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}


function GrowthMetrics({ company }) {
  // Extract necessary metrics
  const epsAnnual = company?.metrics?.epsAnnual || 0;
  const epsGrowth3Y = company?.metrics?.epsGrowth3Y || 0;
  const epsGrowth5Y = company?.metrics?.epsGrowth5Y || 0;
  const epsGrowthQuarterlyYoy = company?.metrics?.epsGrowthQuarterlyYoy || 0;
  const epsGrowthTTMYoy = company?.metrics?.epsGrowthTTMYoy || 0;

  // Calculate annualized growth rates
  const annualGrowth3Y = Math.pow(1 + epsGrowth3Y / 100, 1 / 3) - 1;
  const annualGrowth5Y = Math.pow(1 + epsGrowth5Y / 100, 1 / 5) - 1;

  // Calculate EPS values for each year
  const data = [
    { year: 'Year 1', eps: epsAnnual * Math.pow(1 + annualGrowth3Y, 1) },
    { year: 'Year 2', eps: epsAnnual * Math.pow(1 + annualGrowth3Y, 2) },
    { year: 'Year 3', eps: epsAnnual * Math.pow(1 + annualGrowth3Y, 3) },
    { year: 'Year 4', eps: epsAnnual * Math.pow(1 + annualGrowth5Y, 4) },
    { year: 'Year 5', eps: epsAnnual * Math.pow(1 + annualGrowth5Y, 5) },
  ];

  // Calculate dynamic change percentages
  const changes = {
    epsGrowth3YChange: epsGrowthTTMYoy
      ? ((epsGrowth3Y - epsGrowthTTMYoy) / Math.abs(epsGrowthTTMYoy)) * 100
      : 0,
    epsGrowth5YChange: epsGrowthQuarterlyYoy
      ? ((epsGrowth5Y - epsGrowthQuarterlyYoy) / Math.abs(epsGrowthQuarterlyYoy)) * 100
      : 0,
    epsQuarterlyChange: epsGrowthQuarterlyYoy,
    epsTTMChange: epsGrowthTTMYoy,
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        Growth Metrics
        <div className="relative group ml-2">
          <IoInformationCircleSharp
            className="hover:text-blue-500 cursor-pointer"
            size={16}
          />
          <div className="absolute left-1/2 -translate-x-1/2 font-normal bottom-full mb-2 bg-black text-white text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none w-[20vw]">
            Growth metrics indicate the health and growth potential of investments.
          </div>
        </div>
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <MetricCard
          title="EPS Growth (3Y)"
          value={`${epsGrowth3Y.toFixed(2)}%`}
          change={`${changes.epsGrowth3YChange.toFixed(2)}%`}
          info="EPS Growth (3Y) measures the compound annual growth rate of earnings per share over the last 3 years."
        />
        <MetricCard
          title="EPS Growth (5Y)"
          value={`${epsGrowth5Y.toFixed(2)}%`}
          change={`${changes.epsGrowth5YChange.toFixed(2)}%`}
          info="EPS Growth (5Y) measures the compound annual growth rate of earnings per share over the last 5 years."
        />
        <MetricCard
          title="EPS Growth Quarterly YoY"
          value={`${epsGrowthQuarterlyYoy.toFixed(2)}%`}
          change={`${changes.epsQuarterlyChange.toFixed(2)}%`}
          info="EPS Growth Quarterly YoY compares the earnings per share of the most recent quarter to the same quarter of the previous year."
        />
        <MetricCard
          title="EPS TTM Growth YoY"
          value={`${epsGrowthTTMYoy.toFixed(2)}%`}
          change={`${changes.epsTTMChange.toFixed(2)}%`}
          info="EPS TTM Growth YoY compares the trailing twelve months' earnings per share to the same period from the previous year."
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="eps" stroke="#10B981" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

function RiskMetrics({ company }) {
  const metrics = company?.metrics || {};

  // Extract relevant metrics
  const beta = metrics.beta || 0;
  const volatility = metrics['3MonthADReturnStd'] || 0;
  const high52Week = metrics['52WeekHigh'] || 0;
  const low52Week = metrics['52WeekLow'] || 0;

  // Data for BarChart
  const data = [
    { name: 'Beta', value: beta },
    { name: 'Volatility (3M)', value: volatility },
    { name: '52W High', value: high52Week },
    { name: '52W Low', value: low52Week },
  ];

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        Risk and Volatility Metrics
        <div className="relative group ml-2">
          <IoInformationCircleSharp
            className="hover:text-blue-500 cursor-pointer"
            size={16}
          />
          <div className="absolute left-1/2 -translate-x-1/2 font-normal bottom-full mb-2 bg-black text-white text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none w-[20vw]">
            Risk and volatility metrics help determine portfolio alignment with risk tolerance.
          </div>
        </div>
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <MetricCard
          title="Beta"
          info="Measures price volatility relative to the market."
          value={beta.toFixed(2)}
        />
        <MetricCard
          title="Volatility (3M)"
          info="Captures the variability in a stock's price or returns over a specific period, reflecting its risk."
          value={`${volatility.toFixed(2)}%`}
        />
        <MetricCard
          title="52 Week High"
          info="The highest price a stock has reached in the past 52 weeks, indicating peak performance."
          value={`$${high52Week.toFixed(2)}`}
        />
        <MetricCard
          title="52 Week Low"
          info="The lowest price a stock has reached in the past 52 weeks, indicating trough performance."
          value={`$${low52Week.toFixed(2)}`}
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#F59E0B" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

function LiquidityMetrics({ company }) {
  // Extracting necessary values from the company data
  const debtEquityAnnual = company?.metrics?.['totalDebt/totalEquityAnnual'] || 0;
  const currentRatioAnnual = company?.metrics?.currentRatioAnnual || 0;
  const quickRatioAnnual = company?.metrics?.quickRatioAnnual || 0;

  const data = [
    { name: 'D/E Ratio', value: debtEquityAnnual || 0 },
    { name: 'Current Ratio', value: currentRatioAnnual || 0 },
    { name: 'Quick Ratio', value: quickRatioAnnual || 0 },
  ];

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        Liquidity and Leverage Metrics
        <div className="relative group ml-2">
          <IoInformationCircleSharp
            className="hover:text-blue-500 cursor-pointer"
            size={16}
          />
          <div className="absolute left-1/2 -translate-x-1/2 font-normal bottom-full mb-2 bg-black text-white text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none w-[20vw]">
            These metrics assess financial health and liquidity.
          </div>
        </div>
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
        <MetricCard 
          title="Debt-to-Equity Ratio" 
          info="Indicates reliance on debt versus equity." 
          value={debtEquityAnnual.toFixed(2)} 
        />
        <MetricCard 
          title="Current Ratio" 
          info="Measures short-term liquidity." 
          value={currentRatioAnnual.toFixed(2)} 
        />
        <MetricCard 
          title="Quick Ratio" 
          info="Evaluates the ability to meet interest payments." 
          value={quickRatioAnnual.toFixed(2)} 
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#FBBF24"
            dataKey="value"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
