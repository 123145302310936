import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/Server";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { PlusCircle, RefreshCw, AlertCircle } from "lucide-react";
import {
	calculateBollingerBands,
	calculateDailyChange,
	calculateDailyVolatility,
	calculateEMA,
	calculateMACD,
	calculateRSI,
	calculateSMA,
	calculateSharpeRatio,
	calculateBeta,
	calculatePortfolioVolatility,
	calculateMaxDrawdown

} from "../metrics/stockCalculations";

export default function DashboardTable() {
	const navigate = useNavigate();
	const [selectedStockMetrics, setSelectedStockMetrics] = useState(null);
	const [userPortfolioData, setUserPortfolioData] = useState([]);
	const [columns, setColumns] = useState([
		{ key: "stockName", value: "Stock Name", width: "18%" },
		{ key: "stocksBought", value: "Stocks Bought", width: "18%" },
		{ key: "pricePerStock", value: "Price Per Stock", width: "18%" },
		{ key: "purchasedDate", value: "Purchased Date", width: "18%" },
		{ key: "tradeFees", value: "Trade Fees", width: "18%" },
	]);
	const [loading, setLoading] = useState(false);

	const handleGotoManageDashboard = () => {
		navigate("/manage-portfolio");
	};

	const numericDateToYyyyMmDd = (numericDate) => {
		const baseDate = new Date(Date.UTC(1900, 0, 1));
		const excelOffset = numericDate - 1;
		const actualDate = new Date(
			baseDate.getTime() + excelOffset * 24 * 60 * 60 * 1000
		);
		const year = actualDate.getUTCFullYear();
		const month = String(actualDate.getUTCMonth() + 1).padStart(2, "0");
		const day = String(actualDate.getUTCDate()).padStart(2, "0");

		return `${day}-${month}-${year}`;
	};

	const handleOpenAiReportModal = async () => {
		await aiftAnalytics.actionCall("reportModalOpenButtonClicked", "ai");
	};

	const getUserData = useCallback(async () => {
		setLoading(true);
		const lastRefreshed = localStorage.getItem("lastRefreshed");
		const now = new Date();

		if (
			lastRefreshed &&
			localStorage.getItem("portfolioData") &&
			localStorage.getItem("portfolioColumns")
		) {
			const lastRefreshedDate = new Date(JSON.parse(lastRefreshed));
			const timeDiff = (now - lastRefreshedDate) / (1000 * 60 * 60);

			if (timeDiff < 24) {
				const cachedData = JSON.parse(localStorage.getItem("portfolioData"));
				const cachedColumns = JSON.parse(
					localStorage.getItem("portfolioColumns")
				);
				setUserPortfolioData(cachedData);
				setColumns(cachedColumns);
				setLoading(false);
				return;
			}
		}
		const data = await server.getUserPortfolio();
		if (data.data && data.data.code === 200) {
			const portfolioData = data.data.data.portfolioData.map((item) => {
				const newItem = { ...item };
				for (let key in newItem) {
					if (
						typeof newItem[key] === "number" &&
						newItem[key] > 40000 &&
						key.toLowerCase().includes("date")
					) {
						newItem[key] = numericDateToYyyyMmDd(newItem[key]);
					}
				}
				return newItem;
			});

			setUserPortfolioData(portfolioData);
			localStorage.setItem("portfolioData", JSON.stringify(portfolioData));
			localStorage.setItem("lastRefreshed", JSON.stringify(now));

			if (data.data.data.portfolioType === "excelSheetData") {
				const cols = Object.keys(portfolioData[0]).map((key) => ({
					key: key,
					value:
						key.charAt(0).toUpperCase() +
						key.slice(1).replace(/([A-Z])/g, " $1"),
					width: "10%",
				}));
				setColumns(cols);
				localStorage.setItem("portfolioColumns", JSON.stringify(cols));
			}
		}
		setLoading(false);
	}, []);

	const handleCalculateMetrics = async (stock) => {
		console.log("🚀 ~ handleCalculateMetrics ~ stock:", stock);
		try {
	
			// Fetch graph data
			const response = await server.getGraphData();
			const { "Time Series (Daily)": timeSeries } = response.data.data;
			console.log("🚀 ~ handleCalculateMetrics ~ response:", response.data.data);
	
			// Extract relevant data for calculations
			const dates = Object.keys(timeSeries);
			const closingPrices = dates.map((date) =>
				parseFloat(timeSeries[date]["4. close"])
			);
	
			const dailyMetrics = {
				open: parseFloat(timeSeries[dates[0]]["1. open"]),
				high: parseFloat(timeSeries[dates[0]]["2. high"]),
				low: parseFloat(timeSeries[dates[0]]["3. low"]),
				close: parseFloat(timeSeries[dates[0]]["4. close"]),
				volume: parseInt(timeSeries[dates[0]]["5. volume"], 10),
			};
	
			// Calculate financial metrics
			const dailyChange = calculateDailyChange(dailyMetrics.open, dailyMetrics.close);
			const dailyVolatility = calculateDailyVolatility(
				dailyMetrics.high,
				dailyMetrics.low,
				dailyMetrics.open,
				dailyMetrics.close
			).dailyVolatility;
	
			// Technical and portfolio-level calculations
			const sma = calculateSMA(closingPrices, 10);
			const ema = calculateEMA(closingPrices, 10);
			const rsi = calculateRSI(closingPrices, 14);
			const macd = calculateMACD(closingPrices, 12, 26);
			const bollingerBands = calculateBollingerBands(closingPrices, 20, 2);
	
			// Portfolio-level calculations
			const portfolioReturns = closingPrices.map((price, idx, arr) =>
				idx === 0 ? 0 : (price - arr[idx - 1]) / arr[idx - 1] * 100
			);
			const sharpeRatio = calculateSharpeRatio(15, 2, calculatePortfolioVolatility(portfolioReturns));
			const beta = calculateBeta(portfolioReturns, portfolioReturns); // Replace with market returns
			const portfolioVolatility = calculatePortfolioVolatility(portfolioReturns);
			const maxDrawdown = calculateMaxDrawdown(closingPrices);
	
			// Set metrics for the modal
			setSelectedStockMetrics({
				stockName: stock['Stock Name'],
				date: dates[0],
				dailyChange: dailyChange.toFixed(2),
				dailyVolatility: dailyVolatility.toFixed(2),
				sma: sma.toFixed(2),
				ema: ema.toFixed(2),
				rsi: rsi.toFixed(2),
				macd: macd.toFixed(2),
				bollingerUpper: bollingerBands.upperBand.toFixed(2),
				bollingerMiddle: bollingerBands.middleBand.toFixed(2),
				bollingerLower: bollingerBands.lowerBand.toFixed(2),
				sharpeRatio: sharpeRatio.toFixed(2),
				beta: beta.toFixed(2),
				portfolioVolatility: portfolioVolatility.toFixed(2),
				maxDrawdown: maxDrawdown.toFixed(2),
			});
		} catch (error) {
			console.error("Error calculating metrics:", error);
		}
	};
	

	useEffect(() => {
		getUserData();
	}, [getUserData]);

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="bg-white rounded-xl shadow-lg p-6">
				<div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
					<h2 className="text-xl md:text-2xl font-bold text-gray-800">
						Connected Data
					</h2>
					<button
						onClick={
							userPortfolioData.length === 0 ? null : handleOpenAiReportModal
						}
						className={`w-full md:w-auto px-4 py-2 rounded-lg transition-colors duration-300 flex items-center justify-center ${
							userPortfolioData.length === 0
								? "bg-gray-300 text-gray-400 cursor-not-allowed"
								: "bg-blue-600 text-white hover:bg-blue-700"
						}`}
						disabled={userPortfolioData.length === 0}
					>
						<AlertCircle className="w-5 h-5 mr-2" />
						<span className="text-sm md:text-base">Generate AI Report</span>
					</button>
				</div>
	
				{loading ? (
					<div className="flex justify-center items-center h-64">
						<RefreshCw className="w-12 h-12 text-blue-500 animate-spin" />
					</div>
				) : !userPortfolioData || userPortfolioData.length === 0 ? (
					<div className="flex flex-col items-center justify-center h-64">
						<img src="/icons/noData.png" className="h-20 mb-4" alt="No data" />
						<h3 className="text-xl font-semibold text-gray-700 mb-4">
							No data to show here
						</h3>
						<button
							onClick={handleGotoManageDashboard}
							className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
						>
							<PlusCircle className="w-5 h-5 mr-2" />
							Add Data
						</button>
					</div>
				) : (
					<div className="overflow-x-auto">
						<table className="w-full mb-8">
							<thead>
								<tr>
									{columns.map((col, idx) => (
										<th
											key={idx}
											className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
										>
											{col.value}
										</th>
									))}
									<th className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
										Finance Metrics
									</th>
								</tr>
							</thead>
							<tbody>
								{userPortfolioData.map((data, index) => (
									<tr
										key={index}
										className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
									>
										{columns.map((col, idx) => (
											<td
												key={idx}
												className="px-4 py-2 whitespace-nowrap text-sm text-gray-700"
											>
												{data[col.key]}
											</td>
										))}
										<td className="px-4 py-2 whitespace-nowrap text-sm text-gray-700">
											<button
												className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-300"
												onClick={() => handleCalculateMetrics(data)}
											>
												View Metrics
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
	
						{/* Metrics Section */}
						{selectedStockMetrics && (
							<div className="mt-6 bg-gray-100 rounded-lg p-6">
								<h3 className="text-xl font-semibold text-gray-800 mb-4">
									Metrics for {selectedStockMetrics.stockName} on{" "}
									{selectedStockMetrics.date}
								</h3>
								<table className="w-full">
									<tbody>
										<tr>
											<td className="font-medium text-gray-700 py-2">Daily Change:</td>
											<td className="text-gray-700 py-2">
												{selectedStockMetrics.dailyChange}%
											</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">
												Daily Volatility:
											</td>
											<td className="text-gray-700 py-2">
												{selectedStockMetrics.dailyVolatility}%
											</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">
												SMA (10 days):
											</td>
											<td className="text-gray-700 py-2">{selectedStockMetrics.sma}</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">
												EMA (10 days):
											</td>
											<td className="text-gray-700 py-2">{selectedStockMetrics.ema}</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">RSI:</td>
											<td className="text-gray-700 py-2">{selectedStockMetrics.rsi}</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">MACD:</td>
											<td className="text-gray-700 py-2">{selectedStockMetrics.macd}</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">
												Bollinger Bands:
											</td>
											<td className="text-gray-700 py-2">
												Upper: {selectedStockMetrics.bollingerUpper} | Middle:{" "}
												{selectedStockMetrics.bollingerMiddle} | Lower:{" "}
												{selectedStockMetrics.bollingerLower}
											</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">Sharpe Ratio:</td>
											<td className="text-gray-700 py-2">
											{selectedStockMetrics?.sharpeRatio}
											</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">Beta:</td>
											<td className="text-gray-700 py-2">
											 {selectedStockMetrics?.beta}
											</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">Portfolio Volatility:</td>
											<td className="text-gray-700 py-2">
											 {selectedStockMetrics?.portfolioVolatility}%
											</td>
										</tr>
										<tr>
											<td className="font-medium text-gray-700 py-2">Maximum Drawdown:</td>
											<td className="text-gray-700 py-2">
											 {selectedStockMetrics?.maxDrawdown}%
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);	
}
