import React, { useState, useEffect } from "react";
import Dashboard from "../demo/Dashboard.jsx";
import MetricsCustomizer from "../demo/MetricsCustomizer.jsx";
import AiArenaLayout from "../demo/AiArenaLayout.jsx";
import { FloatingChat } from "../demo/FloatingChat.jsx";

const DemoPage = () => {
  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [loaderTextIndex, setLoaderTextIndex] = useState(0);
  const generatingText = [
    "Processing your customized parameters...",
    "Analyzing market data to generate insights...",
    "Evaluating key stock metrics for the best results...",
    "Optimizing your report for maximum accuracy...",
    "Generating insights based on your selected metrics..."
  ];

  const customizedParameters = (e) => {
    handleGenerateInsights(e.additionalContext, e.selectedMetrics);
  };

  const handleGenerateInsights = async (context, metrics) => {
    try {
      setCurrPage(1);
      setLoading(true);

      // Simulate loading by setting a delay of 16 seconds
      setTimeout(() => {
        // After the loading duration, set loading to false to show results
        setLoading(false);
      }, 16000); // 16 seconds

      // Simulate API call - you can uncomment and call your real API here
      // let res = (await axios.post(`${apiURL}/api/demo/generate-insights`, { context, metrics })).data;
      // console.log(res);

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoaderTextIndex((prevIndex) => (prevIndex + 1) % generatingText.length);
      }, 3000); // Change text every 3 seconds (total rotation takes 15 seconds)

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [loading, generatingText.length]);

  return (
    <div className="container mx-auto p-4">
      {currPage === 0 ? (
        <>
          <Dashboard />
          <MetricsCustomizer sendToParent={customizedParameters} />
        </>
      ) : (
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "95vh",
              }}
            >
              <img src="/icons/loader.gif" style={{ height: "100px" }} alt="Loading..." />
              <h3>{generatingText[loaderTextIndex]}</h3>
            </div>
          ) : (
            <AiArenaLayout />
          )}
        </>
      )}
      <FloatingChat/>
    </div>
  );
};

export default DemoPage;
